<template>
  <div class="mb-3 hover-actions">
    <div v-if="item.type === 'Comment'" class="d-flex flex-column"
      :class="itemOwner ? 'align-items-end' : 'align-items-start'">
      <div v-if="editForm" class="w-100 position-relative">
        <form @submit.prevent="validateEditForm()" class="w-100">
          <textarea v-model="editMessage" id="editMessage" type="text" class="form-control mb-1" rows="1"></textarea>
        </form>
        <div class="bg-dark position-absolute rounded shadow-sm"
          style="border: 1px solid #696969;bottom: calc(100% - 0.5em);right: 0.5em; z-index: 100">
          <button class="btn btn-sm btn-link text-white" @click.prevent="validateEditForm()"
            v-tooltip="$t('js.actions.submit')">
            <i class="fas fa-check"></i>
          </button>
          <button class="btn btn-sm btn-link text-white" @click.prevent="editForm = null; editMessage = ''"
            v-tooltip="$t('js.actions.cancel')">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div v-else class="d-flex flex-wrap align-items-center mb-1 position-relative w-100"
        :class="{ 'flex-row-reverse': itemOwner }">
        <div v-if="itemOwner || !itemDeleted || didDeleteItem" class="d-inline-block rounded p-2" :class="messageColor"
          style="word-break: break-word; hyphens: auto">
          <i v-if="itemDeleted" class="fas fa-eye-slash fa-xs me-2"
            v-tooltip="$t('js.tooltips.only_visible_to_you')"></i>
          <span>{{ item.message }}</span>
        </div>
        <PostActions v-if="itemOwner || userAdmin" :post="item" @prepareEdit="prepareEdit" />
      </div>
      <div v-if="itemDeleted" class="mb-1 small text-white-50 ">
        <i class="fas fa-trash-alt me-1"></i>
        <i>{{ $t("js.sidebar.comments.deleted_by") }} {{ $t(`js.sidebar.general.deleted_by.${item.deleted_by.type}`)
          }}</i>
      </div>
      <div class="d-flex small text-white-50" :class="itemOwner ? 'flex-row-reverse' : ''">
        <div><i v-if="item.owner.type === 'Admin'" class="fas fa-user-cog me-2"
          v-tooltip="$t('js.sidebar.general.resource.Admin')"></i>{{ item.owner.deleted ?
          $t('js.sidebar.general.deleted', { resource: $t(`js.sidebar.general.resource.${item.owner.type}`) }) :
          item.owner.name }} - <time :datetime="item.created_at">{{ $d(item.created_at, "long") }}</time></div>
        <div v-if="item.edited && !itemDeleted" class="mx-2"> - </div>
        <div v-if="item.edited && !itemDeleted">{{ $t("js.sidebar.general.edited") }}</div>
      </div>
    </div>
    <div v-else-if="item.type === 'Amendment'"
      class="d-flex align-items-center justify-content-center mb-3 text-white-50">
      <i class="p-3 fas fa-file-signature"></i>
      <span class="small"
        v-html="$t('js.sidebar.comments.amendment_by_created_for_html', { name: item.owner.deleted ? $t('js.sidebar.general.deleted', { resource: $t(`js.sidebar.general.resource.${item.owner.type}`) }) : item.owner.name, ballot: item.target_slide.deleted ? $t('js.sidebar.general.deleted', { resource: $t('js.sidebar.general.resource.slide') }) : item.target_slide.title[firstAvailableLocale] })"></span>
    </div>
    <div v-else-if="item.type === 'Candidacy'"
      class="d-flex align-items-center justify-content-center mb-3 text-white-50">
      <i class="p-3 fas fa-user"></i>
      <span class="small"
        v-html="$t('js.sidebar.comments.candidacy_by_created_for_html', { name: item.owner.deleted ? $t('js.sidebar.general.deleted', { resource: $t(`js.sidebar.general.resource.${item.owner.type}`) }) : item.owner.name, ballot: item.target_slide.deleted ? $t('js.sidebar.general.deleted', { resource: $t('js.sidebar.general.resource.slide') }) : item.target_slide.title[firstAvailableLocale]})"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import PostActions from "../PostActions.vue";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { useVotingModulesStore } from "@/entrypoints/stores/voting_modules";
import type { PropType, UserPost } from "@/types";

export default defineComponent({
  name: "Comment",
  components: { PostActions },
  props: {
    item: {
      type: Object as PropType<UserPost>,
      required: true,
    },
  },
  data() {
    return {
      editForm: false,
      editMessage: "",
      response: "",
      read: false,
    }
  },
  computed: {
    ...mapState(useVotingModulesStore, ["chatUser"]),
    ...mapState(useSharedStore, ["firstAvailableLocale"]),
    itemOwner() {
      return this.item.owner.id === this.chatUser.id && this.item.owner.type === this.chatUser.type;
    },
    itemDeleted() {
      return this.item.deleted_by && this.item.deleted_by.id && this.item.deleted_by.type;
    },
    messageColor() {
      let bg = "";
      if (this.item.owner.type === "Admin") bg += "border border-light";
      if (this.itemOwner && !this.itemDeleted) bg += " bg-dark-transparent text-white";
      else if (this.itemDeleted) bg += " text-white-50 bg-light-transparent";
      else bg += " bg-theme";
      return bg;
    },
    userAdmin() {
      return this.chatUser.type === "Admin"
    },
    didDeleteItem() {
      return this.item.deleted_by.id
        && this.item.deleted_by.type
        && this.chatUser.type === this.item.deleted_by.type
        && this.item.deleted_by.id === this.chatUser.id;
    },
  },
  methods: {
    ...mapActions(useVotingModulesStore, ["editPost"]),
    prepareEdit() {
      this.editMessage = this.item.message;
      this.editForm = true;
      this.$nextTick(function () {
        $("#message").focus();
      });
    },
    async validateEditForm() {
      if (this.editMessage.length > 0) {
        await this.editPost({
          id: this.item.id,
          post: {
            message: this.editMessage,
          },
        }).then((res: any) => {
          if (res?.status === 200) {
            this.editMessage = ""
            this.editForm = false
          }
          this.response = res.message;
        });
      }
    },
  },
});
</script>
