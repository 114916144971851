<template>
  <div class="mb-3 hover-actions position-relative">
    <EditForm v-if="isEditing" :item="item" @closeForm="editForm = false" />
    <div v-else class="mb-1 sidebar-card" :class="{ 'pulse-shadow': unread }" @focus="unread ? markPostRead(item.id) : null" 
      @mouseover="unread ? markPostRead(item.id) : null">
      <div v-if="itemOwner || !itemDeleted || didDeleteItem">
        <div v-if="item.addedToBallot" class="small px-1 position-absolute bg-success text-center text-white" 
          style="border-bottom-left-radius: 10px;border-top-right-radius: 3px; top: 0; right: 0">
          <i class="fas fa-check"></i> {{ $t("js.sidebar.candidacies.accepted") }}
        </div>
        <div class="p-3 rounded-top" :class="messageColor">
          <i v-if="itemDeleted" class="fas fa-eye-slash mb-2 float-end" v-tooltip="$t('js.tooltips.only_visible_to_you')"></i>
          <h5><i class="fas fa-user"></i>
            {{ item.owner.deleted 
              ? $t("js.sidebar.general.deleted", {resource: $t(`js.sidebar.general.resource.${item.owner.type}`)})
              : item.owner.name
            }}</h5>
          <span>{{ $t("js.sidebar.general.for") }} {{item.target_slide.deleted ? $t("js.sidebar.general.deleted", {resource: $t("js.sidebar.general.resource.slide")}) : item.target_slide.title[firstAvailableLocale] }}</span>
        </div>
        <div class="p-3 text-white" style="white-space: pre-wrap; overflow-wrap: break-word;" :class="itemDeleted ? 'text-white-50' : 'text-white'">{{ item.message }}</div>
      </div>
      <div v-if="itemDeleted" class="d-flex align-items-center justify-content-center small p-3 text-white-50">
        <i class="pe-3 fas fa-trash-alt"></i>
        <span>{{ $t("js.sidebar.candidacies.deleted_by") }} {{ $t(`js.sidebar.general.deleted_by.${item.deleted_by.type}`) }}</span>
      </div>
      <PostActions :post="item" :editing="isEditing" @prepareEdit="editForm = true" @cancelEdit="editForm = false"/>
    </div>
    <div class="d-flex justify-content-between small text-white-50">
      <span>
        {{ item.owner.deleted
          ? $t("js.sidebar.general.deleted", {resource: $t(`js.sidebar.general.resource.${item.owner.type}`)})
          : item.owner.name }}
      </span>
      <span v-if="item.edited && !itemDeleted">{{ $t("js.sidebar.general.edited") }}</span>
      <time :datetime="item.created_at">{{ $d(item.created_at, "long") }}</time>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import PostActions from "../PostActions.vue";
import EditForm from "./EditForm.vue";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useVotingModulesStore } from "@/entrypoints/stores/voting_modules";
import type { PropType, UserPost, Slide } from "@/types";

export default defineComponent({
  name: "Candidacy",
  components: { EditForm, PostActions },
  props: {
    item: {
      type: Object as PropType<UserPost>,
      required: true,
    }
  },
  data() {
    return {
      editForm: false,
      read: false,
    }
  },
  computed: {
    ...mapState(useVotingModulesStore, ["chatUser"]),
    ...mapState(usePresentationStore, ["slides"]),
    ...mapState(useSharedStore, ["firstAvailableLocale"]),
    isEditing() {
      return this.editForm && !this.item.addedToBallot;
    },
    unread() {
      return this.item.unread;
    },
    itemOwner() {
      return (this.item.owner.id === this.chatUser.id && this.item.owner.type === this.chatUser.type);
    },
    ballotSlides() {
      return this.slides.filter((s: Slide) => s.type === "Slides::BallotSlide");
    },
    messageColor() {
      return this.itemDeleted ? "text-white-50 bg-light-transparent" : "bg-theme";
    },
    itemDeleted() {
      return this.item.deleted_by && this.item.deleted_by.id && this.item.deleted_by.type;
    },
    didDeleteItem() {
      return this.item.deleted_by.id
        && this.item.deleted_by.type
        && this.chatUser.type === this.item.deleted_by.type
        && this.item.deleted_by.id === this.chatUser.id;
    },
  },
  methods: {
    ...mapActions(useVotingModulesStore, ["markPostRead"]),
  },
});
</script>
